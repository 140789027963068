<template>
    <div>
        <ByBreads>我的帳號</ByBreads>
        <el-row>
            <el-col :lg="24" :md="24" :xs="24">
                <el-card>
                    <div v-if="loginFlg==1">
                        <el-row>
                            <el-col :lg="7">&nbsp;</el-col>
                            <el-col :lg="10" style="text-align: center;"><h2>WELCOME</h2></el-col>
                            <el-col :lg="7">&nbsp; </el-col>
                        </el-row>
                        <el-row>
                            <el-col :lg="7">&nbsp; </el-col>
                            <el-col :lg="10">
                                <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" key="ruleForm"
                                         label-position="top" label-width="80px"
                                         @keyup.enter.native="submitForm('ruleForm')">
                                    <el-form-item  prop="userNo">
                                        <el-input v-model="ruleForm.userNo" placeholder="電子郵件"></el-input>
                                    </el-form-item>
                                    <el-form-item  prop="pass">
                                        <el-input type="password" v-model="ruleForm.pass" autocomplete="off" placeholder="密码"></el-input>
                                    </el-form-item>

                                    <div>
                                        <p>按一下下方的[登入]，即表示您同意蜂會員 <a href="#">服務條款</a> 並認可 <a href="#">隱私政策</a></p>
                                    </div>
                                    <div style="display: flex;justify-content: space-between">
                                        <el-checkbox v-model="isRemember">記住帳號</el-checkbox>
                                        <el-button size="mini" type="danger" @click="forgotPwd">忘記密碼</el-button>
                                    </div>
                                    <el-form-item style="margin: 20px 0px;text-align: center;">
                                        <el-button type="primary" style="width: 80%;color: white;background-color: #FFB000;border-color: #FFB000;cursor: pointer" @click="submitForm('ruleForm')">登入</el-button>
                                    </el-form-item>
                                    <el-divider content-position="center"></el-divider>
                                    <div style="text-align: center;margin: 20px;">
                                        <h2>快速登入</h2>
                                    </div>

                                    <div style="display: flex;justify-content: space-evenly">
                                        <div style="text-align: center;">
                                            <a style="font-size: 20px;" href="login/google">
                                                <img :src="loginIconGl" class="login-icon"/>
                                            </a>
                                            <div>Google</div>
                                        </div>
                                        <div v-if="0>1" style="text-align: center;" >
                                            <a style="font-size: 20px;" href="login/facebook">
                                                <img :src="loginIconFb" class="login-icon login-icon-fb"/>
                                            </a>
                                            <div>Facebook</div>
                                        </div>
                                        <div style="text-align: center;" >
                                            <a style="" href="login/line">
                                                <img :src="loginIconLine" class="login-icon"/>
                                            </a>
                                            <div>Line</div>
                                        </div>
                                    </div>
                                    <div style="display: flex;justify-content: flex-end;color: #FFB000"><p >沒有帳號?<a @click="btnRegist" style="color: #FFB000;cursor: pointer;">注册</a></p></div>
                                </el-form>
                            </el-col>
                            <el-col :lg="7">&nbsp; </el-col>
                        </el-row>
                    </div>
                    <div v-else-if="loginFlg==2">
                        <el-row>
                            <el-col :lg="7">&nbsp;</el-col>
                            <el-col :lg="10" style="text-align: center;"><h2>註冊帳號</h2></el-col>
                            <el-col :lg="7">&nbsp; </el-col>
                        </el-row>
                        <el-row>
                            <el-col :lg="7">&nbsp; </el-col>
                            <el-col :lg="10">
                                <el-form :model="ruleForm" status-icon :rules="rulesReg" ref="ruleRegForm" key="ruleRegForm"
                                         label-position="top" label-width="80px">
                                    <el-form-item prop="userNo">
                                        <el-input v-model="ruleForm.userNo" placeholder="電子郵件"></el-input>
                                    </el-form-item>
                                    <el-form-item prop="pass">
                                        <el-input type="password" v-model="ruleForm.pass" autocomplete="off" placeholder="密码"></el-input>
                                    </el-form-item>
                                    <el-form-item prop="checkPass">
                                        <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off" placeholder="確認密碼"></el-input>
                                    </el-form-item>
                                    <div>
                                        <p>按一下下方的[繼續]，即表示您同意蜂會員 <a href="#">服務條款</a> 並認可 <a href="#">隱私政策</a></p>
                                    </div>
                                    <el-form-item style="margin: 20px 0px;text-align: center;">
                                        <el-button style="width: 80%;color: white;background-color: #FFB000;border-color: #FFB000;" type="primary" @click="submitForm('ruleRegForm')">繼續</el-button>
                                    </el-form-item>
                                    <el-divider content-position="center"></el-divider>
                                    <div style="text-align: center;margin: 20px;">
                                        <h2>快速註冊</h2>
                                    </div>

                                    <div style="display: flex;justify-content: space-evenly">
                                        <div style="text-align: center;">
                                            <a style="font-size: 20px;" href="login/google">
                                                <img :src="loginIconGl" class="login-icon"/>
                                            </a>
                                            <div>Google</div>
                                        </div>
                                        <div v-if="0>1" style="text-align: center;">
                                            <a style="font-size: 20px;" href="login/facebook">
                                                <img :src="loginIconFb" class="login-icon login-icon-fb"/>
                                            </a>
                                            <div>Facebook</div>
                                        </div>
                                        <div style="text-align: center;">
                                            <a style="" href="login/line">
                                                <img :src="loginIconLine" class="login-icon"/>
                                            </a>
                                            <div>Line</div>
                                        </div>
                                    </div>
                                    <div style="display: flex;justify-content: flex-end;color: #FFB000"><p >已有帳號?<a @click="btnRegist" style="color: #FFB000;cursor: pointer">登入</a></p></div>

                                </el-form>
                            </el-col>
                            <el-col :lg="7">&nbsp; </el-col>
                        </el-row>
                    </div>
                    <div v-else-if="loginFlg==3">
                        <h3>忘记密码</h3>
                        <el-form :model="ruleForm" status-icon :rules="rulesReg" ref="ruleForgotForm" key="ruleForgotForm"
                                 label-position="top" label-width="80px" class="demo-ruleForm">
                            <el-form-item label="電子郵件" prop="userNo">
                                <el-input v-model="ruleForm.userNo"></el-input>
                            </el-form-item>
                            <el-form-item>
                                我們會將密碼傳送至您的電子郵件地址。
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="submitRegForm('ruleRegForm')">註冊</el-button>
                                <el-button @click="resetForm('ruleRegForm')">重置</el-button>
                            </el-form-item>

                        </el-form>
                    </div>
                </el-card>

            </el-col>
        </el-row>
    </div>
</template>

<script>
    import ByBreads from '@/components/MyBreads.vue';
    import {GetUserLogin} from '@/request/api';
    import {mapActions} from 'vuex';

    export default {
        name: '',
        components: {
            ByBreads
        },
        data() {
            var checkUserNo = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('請輸入電子郵件'));
                }
                setTimeout(() => {
                    let emailReg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
                    if (emailReg.test(value) || value == '') {
                        callback();
                    } else {
                        callback(new Error('請輸入正確的電子郵件'));
                    }
                }, 1000);
            };
            var validatePassForLogin = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入密码'));
                }
                callback();
            };
            var validatePass = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入密码'));
                } else {
                    if (this.ruleForm.checkPass !== '') {
                        this.$refs.ruleRegForm.validateField('checkPass');
                    }
                    callback();
                }
            };
            var validatePass2 = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('請再次輸入密碼'));
                } else if (value !== this.ruleForm.pass) {
                    callback(new Error('兩次輸入密碼不一致!'));
                } else {
                    callback();
                }
            };
            return {
                loginIconFb: require('@/assets/images/login-fb.png'),
                loginIconGl: require('@/assets/images/login-gl.png'),
                loginIconLine: require('@/assets/images/login-line.png'),
                isRemember: false,//记住密码开关
                loginFlg: 1,//1:预设显示login;2:注册;3:找回密码
                btnStr: "快速註冊",
                ruleForm: {
                    pass: '',
                    userNo: '', checkPass: ''
                },
                rules: {
                    userNo: [
                        {required: true, type: 'email', validator: checkUserNo, trigger: 'blur'}
                    ],
                    pass: [
                        {required: true, validator: validatePassForLogin, trigger: 'blur'}
                    ],
                },
                rulesReg: {
                    userNo: [
                        {required: true, type: 'email', validator: checkUserNo, trigger: 'blur'}
                    ],
                    pass: [
                        {required: true, validator: validatePass, trigger: 'blur'}
                    ],
                    checkPass: [
                        {required: true, validator: validatePass2, trigger: 'blur'}
                    ],
                }
            }
        },
        methods: {
            ...mapActions(['getCartNum']),
            submitForm(formName) {
                let _this = this;
                this.$refs[formName].validate((valid) => {
                    if (!valid) {
                        _this.$message.error('請輸入正確的資料');
                        return false;
                    }
                    /* this.$store.dispatch("login", {
                         account: this.ruleForm.userNo
                         , password: this.ruleForm.pass
                         , checkPassword: this.ruleForm.checkPass
                         , spread_spid: 0
                     });*/
                    GetUserLogin({
                        account: this.ruleForm.userNo
                        , password: this.ruleForm.pass
                        , checkPassword: this.ruleForm.checkPass
                        , lgType: this.loginFlg == 2 ? "regist" : "login"
                        , spread_spid: 0
                    }).then(res => {
                        if (res.code == 200) {
                            _this.$store.commit('SET_USER', res.data);
                            _this.$store.commit('SET_LOGINED', true);

                            if (_this.isRemember) {// 判断 如果勾选记住密码
                                _this.setCookies(_this.ruleForm.userNo, _this.ruleForm.pass, 7);
                            } else {// 否则 清空cookies
                                this.clearCookies();
                            }
                            localStorage.setItem('isRemember', this.isRemember);
                            var auth = {"token": res.data.token, "nickName": res.data.nickName, "avatar": res.data.avatar};
                            localStorage.setItem("magor3-auth", JSON.stringify(auth));
                            _this.$message.success(res.message);

                            _this.getCartNum();
                            if (_this.$route.query.redirect) { //如果存在参数
                                let redirect = _this.$route.query.redirect
                                _this.$router.push(redirect)//则跳转至进入登录頁前的路由
                            } else {
                                _this.$router.push('/')//否则跳转至首頁
                            }
                        } else {
                            this.$message.error(res.message,5000);
                        }
                    }).catch(e => {
                        this.$message.error(e.message);
                        console.log(e);
                    })
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            btnRegist() {
                if (this.loginFlg == 2) {
                    this.loginFlg = 1;
                    this.btnStr = "快速註冊";
                } else if (this.loginFlg == 1) {
                    this.loginFlg = 2;
                    this.btnStr = "登入";
                }

            },
            forgotPwd() {
                this.$router.push('/lost-password');
            },
            //保存Cookies账号密码
            setCookies(username, password, exDays = 60) {
                // Encrypt，加密账号密码
                var cipherUsername = this.CryptoJS.AES.encrypt(username + '', 'magor!i@love#you').toString();
                var cipherPsw = this.CryptoJS.AES.encrypt(password + '', 'magor!i@love#you').toString();
                let exDate = new Date(); // 获取时间
                exDate.setTime(exDate.getTime() + 24 * 60 * 60 * 1000 * exDays); // 保存的天数
                this.$cookies.set('currentUsername', cipherUsername, exDate.toUTCString());
                this.$cookies.set('password', cipherPsw, exDate.toUTCString());
            },
            //读取cookies里面的账号密码
            getCookies() {
                let cipherUsername = this.$cookies.get(`currentUsername`);
                let cipherPsw = this.$cookies.get(`password`);
                if (cipherUsername == null || cipherPsw == null) {
                    return;
                }
                // 将用户名解码
                let decryptUsername = this.CryptoJS.AES.decrypt(cipherUsername, `magor!i@love#you`).toString(this.CryptoJS.enc.Utf8);
                // 将密码解码
                let decryptPwd = this.CryptoJS.AES.decrypt(cipherPsw, `magor!i@love#you`).toString(this.CryptoJS.enc.Utf8);
                // 将账号密码付给form
                this.ruleForm.userNo = decryptUsername;
                this.ruleForm.pass = decryptPwd;
            },
            clearCookies() {
                this.$cookies.remove(`currentUsername`);
                this.$cookies.remove(`password`);
            },
        },
        created() {
            if (localStorage.getItem(`isRemember`) === 'true') {
                this.getCookies();
                this.isRemember = true;
            }
        }
    }
</script>
<style lang="less" scoped>
    .login-title{
        font-size: 30px;
        margin: 0 auto;
        text-align: center;
    }

    .login-icon {
        width: 40px;
        height: 40px;
    }
    .login-icon-fb{
        background-color: #0666c9;
    }
</style>
